import React, { useState } from "react"

import "./style.scss"
import "../styles/estimate-styles.css"

const EstimateRepair = () => {
  const samsungPhonesArr = [
    { name: "GALAXY S7", screenPrice: 156, batteryPrice: 75 },
    { name: "GALAXY S8", screenPrice: 223, batteryPrice: 75 },
    { name: "GALAXY S8+", screenPrice: 245, batteryPrice: 75 },
    { name: "GALAXY S9", screenPrice: 240, batteryPrice: 75 },
    { name: "GALAXY S9+", screenPrice: 256, batteryPrice: 75 },
    { name: "GALAXY S10", screenPrice: 350, batteryPrice: 95 },
    { name: "GALAXY S10+", screenPrice: 375, batteryPrice: 95 },
    { name: "GALAXY S20", screenPrice: 250, batteryPrice: 100 },
    { name: "GALAXY S20 Ultra", screenPrice: 350, batteryPrice: 145 },
    { name: "GALAXY S20 Plus", screenPrice: 350, batteryPrice: 125 },
    { name: "GALAXY S21", screenPrice: 250, batteryPrice: 100 },
    { name: "GALAXY S21 Plus", screenPrice: 280, batteryPrice: 125 },
    { name: "GALAXY S21 Ultra", screenPrice: 350, batteryPrice: 145 },
    { name: "GALAXY S22", screenPrice: 250, batteryPrice: 100 },
    { name: "GALAXY S22 Plus", screenPrice: 280, batteryPrice: 125 },
    { name: "GALAXY S22 Ultra", screenPrice: 350, batteryPrice: 145 },
  ]
  const applePhonesArr = [
    { name: "IPHONE 5", screenPrice: 70, batteryPrice: 50 },
    { name: "IPHONE 6s", screenPrice: 70, batteryPrice: 70 },
    { name: "IPHONE 6s+", screenPrice: 75, batteryPrice: 75 },
    { name: "IPHONE 6", screenPrice: 70, batteryPrice: 70 },
    { name: "IPHONE 6+", screenPrice: 75, batteryPrice: 75 },
    { name: "IPHONE 7", screenPrice: 80, batteryPrice: 75 },
    { name: "IPHONE 7+", screenPrice: 90, batteryPrice: 75 },
    { name: "IPHONE 8", screenPrice: 80, batteryPrice: 75 },
    { name: "IPHONE 8+", screenPrice: 90, batteryPrice: 75 },
    { name: "IPHONE X", screenPrice: 145, batteryPrice: 75 },
    { name: "IPHONE XS", screenPrice: 145, batteryPrice: 75 },
    { name: "IPHONE XR", screenPrice: 145, batteryPrice: 75 },
    { name: "IPHONE XS MAX", screenPrice: 180, batteryPrice: 75 },
    { name: "IPHONE SE", screenPrice: 95, batteryPrice: 80 },
    { name: "IPHONE 11", screenPrice: 150, batteryPrice: 95 },
    { name: "IPHONE 11 PRO", screenPrice: 175, batteryPrice: 100 },
    { name: "IPHONE 11 PRO MAX", screenPrice: 200, batteryPrice: 125 },
    { name: "IPHONE 12 MINI", screenPrice: 325, batteryPrice: 120 },
    { name: "IPHONE 12/PRO", screenPrice: 250, batteryPrice: 120 },
    { name: "IPHONE 12 PRO MAX", screenPrice: 380, batteryPrice: 150 },
    { name: "IPHONE 13 MINI", screenPrice: 450, batteryPrice: 150 },
    { name: "IPHONE 13/PRO", screenPrice: 370, batteryPrice: 150 },
    { name: "IPHONE 13 PRO MAX", screenPrice: 430, batteryPrice: 150 },
  ]

  var [selectedBrand, setSelectedBrand] = useState("none")
  var [selectedPrice, setSelectedPrice] = useState(" ")

  const handleBrandSelection = () => {
    const selection = document.getElementsByClassName(
      "estimateBrandSelection"
    )[0].value
    switch (selection) {
      case "SELECT YOUR BRAND":
        setSelectedBrand((selectedBrand = "none"))
        setSelectedPrice((selectedPrice = " "))
        break
      case "SAMSUNG":
        setSelectedBrand((selectedBrand = "samsung"))
        break
      case "APPLE":
        setSelectedBrand((selectedBrand = "apple"))
        break
      default:
        setSelectedBrand((selectedBrand = "none"))
        setSelectedPrice((selectedPrice = " "))
        break
    }
  }

  const togglePrice = () => {
    try {
      var arrName
      var defPrice
      const selectionDevice = document.getElementsByClassName(
        "estimatePhoneSelection"
      )[0].value
      if (
        document.getElementsByClassName("estimateBrandSelection")[0].value ===
        "APPLE"
      ) {
        arrName = applePhonesArr
        defPrice = 200
      } else {
        arrName = samsungPhonesArr
        defPrice = 100
      }
      for (let i = 0; i < arrName.length; i++) {
        var productPrice
        if (
          document.getElementsByClassName("estimateRepairTypeSelection")[0]
            .value === "SCREEN REPAIR"
        ) {
          productPrice = arrName[i].screenPrice
        } else {
          productPrice = arrName[i].batteryPrice
        }
        if (selectionDevice === arrName[i].name) {
          setSelectedPrice((selectedPrice = ": $" + productPrice))
          break
        } else if (selectionDevice === "OTHER") {
          setSelectedPrice((selectedPrice = ": $" + defPrice))
        } else {
          setSelectedPrice((selectedPrice = " "))
        }
      }
    } catch {}
  }

  return (
    <div className="estimateWrapper">
      <h2 className="estimateHeader">Phone Repair Pricing</h2>
      <p className="estimateHeaderPar">
        Feel the form below to get an estimate cost, before you come to us
      </p>
      <div className="estimateSelectionGrid">
        <select
          className="estimateSelection estimateBrandSelection"
          onChange={handleBrandSelection}
        >
          <option className="estimateSelectionOption">SELECT YOUR BRAND</option>
          <option className="estimateSelectionOption">SAMSUNG</option>
          <option className="estimateSelectionOption">APPLE</option>
        </select>
        {selectedBrand === "none" ? (
          <select className="estimateSelection" onChange={() => togglePrice()}>
            <option className="estimateSelectionOption">
              SELECT YOUR DEVICE
            </option>
          </select>
        ) : selectedBrand === "apple" ? (
          <select
            className="estimateSelection estimatePhoneSelection"
            onChange={() => togglePrice()}
          >
            <option className="estimateSelectionOption">
              SELECT YOUR DEVICE
            </option>
            {applePhonesArr.map(phone => {
              return (
                <option className="estimateSelectionOption" key={phone.name}>
                  {phone.name}
                </option>
              )
            })}
            <option className="estimateSelectionOption">OTHER</option>
          </select>
        ) : (
          <select
            className="estimateSelection estimatePhoneSelection"
            onChange={() => togglePrice()}
          >
            <option className="estimateSelectionOption">
              SELECT YOUR DEVICE
            </option>
            {samsungPhonesArr.map(phone => {
              return (
                <option className="estimateSelectionOption" key={phone.name}>
                  {phone.name}
                </option>
              )
            })}
            <option className="estimateSelectionOption">OTHER</option>
          </select>
        )}
        <select
          className="estimateSelection estimateRepairTypeSelection"
          onChange={() => togglePrice()}
        >
          <option className="estimateSelectionOption">SCREEN REPAIR</option>
          <option className="estimateSelectionOption">
            BATTERY REPLACEMENT
          </option>
        </select>
      </div>
      <div className="estimateCostWrapper">
        <h3 className="estimateCostText">
          ESTIMETED COST OF REPAIR
          <span className="estimateCostPrice">{selectedPrice}</span>
        </h3>
      </div>
      <p className="estimateWarning">
        * The repair costs displayed are based on the particular phone model
        that you select. The repair cost amount is an average of the actual
        repair costs for the selected model.
      </p>
    </div>
  )
}

export default EstimateRepair
