import React from "react"

import Layout from "../components/layout"
import Midsection from "../components/midsection"
import EstimateRepair from "../components/repairEstimate"

import { Link } from "gatsby"
import SEO from "../components/seo"
import citiesData from "../data/cities.json"
import "../components/style.scss"
import "../styles/styles.css"

const IndexPage = ({ location }) => {
  var serviceCity
  if (location.state) {
    serviceCity = location.state.serviceCity
  } else {
    serviceCity = "Portland, OR"
  }
  return (
    <Layout serviceCity={serviceCity}>
      <SEO title="PhoneFix PRO" />
      <Midsection />
      <EstimateRepair />
      <div className="ls-background-light">
        <section className="section">
          <div className="container">
            <div className="columns is-multiline">
              <div className="column indexCitiesColumn">
                <h2>Cities We Service</h2>
                {citiesData.map(city => {
                  return (
                    <div key={city.name}>
                      <Link
                        title={`${serviceCity} iPhone Repair Services`}
                        to={`/cell-phone-repair`}
                        state={{ serviceCity: city.name }}
                      >
                        {city.name}
                      </Link>
                    </div>
                  )
                })}
              </div>
              <div className="column">
                <h3>
                  Get to know more about iPhone Repair and Android Repair in
                  {serviceCity}
                </h3>
                <p>
                  Our company understand how hard it is to spend time or to stay
                  connected with the world while your Phone or Tablet is not
                  working properly. You are unable to use Broken iPhone and it
                  frustrates you. We'll do our best to repair your Cracked
                  Android and bring it back to life Get rid of all this
                  troublesome situation and hire us for a getting back in touch
                  with the world. We repair anything relating to these following
                  services: -{" "}
                  <Link
                    to="/cell-phone-repair"
                    title={`${serviceCity} Cell Phone Repair`}
                  >
                    Broken Cell Phone Repair Services
                  </Link>
                  -{" "}
                  <Link
                    to="/cell-phone-repair/iphone-repair"
                    title={`${serviceCity} iPhone Repair`}
                  >
                    Cracked iPhone Repair Services
                  </Link>
                  -{" "}
                  <Link
                    to="/cell-phone-repair/ipad-repair"
                    title={`${serviceCity} iPad Repair`}
                  >
                    iPad Screen Repair Services
                  </Link>
                  -{" "}
                  <Link
                    to="/cell-phone-repair/samsung-repair"
                    title={`${serviceCity} Samsung Phone Repair`}
                  >
                    Samsung Repair Services
                  </Link>
                  -{" "}
                  <Link
                    to="/cell-phone-repair/google-pixel-repair"
                    title={`${serviceCity} Google Pixel Repair`}
                  >
                    Google Pixel Repair Services
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}
export default IndexPage
